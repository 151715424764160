.select-dealer-modal {
    border-radius: 0px !important;
    width: 696px !important;
}

.select-dealer-modal .content {
    padding: 0px !important;
}

.select-dealer-modal .modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0px !important;
}
.select-dealer-modal .dealer-list {
    margin: 0px !important;
    height: 578px;
    overflow-y: scroll;
    display: block;
}
.select-dealer-modal .dealer-item {
    cursor: pointer;
    max-height: 70px;
}

.select-dealer-modal .dealer-item:hover {
    background-color: #e8e8e8 !important;
}

.select-dealer-modal .dealer-item.active {
    background-color: #DFDFDF;
}
.select-dealer-modal .dealer-item .dealer-name {
    padding-left: 30px !important;
}
.select-dealer-modal .dealer-item .dealer-address {
    text-align: right;
    padding-right: 30px !important;
}

.select-dealer-modal .search-input-container {
    width: 100%;
    padding: 0px 25px;
    position: relative;
    margin-bottom: 20px;
}
.select-dealer-modal .search-input-container input.search {
    width: 100%;
    resize: none;
    outline: none;
    border: 1px solid #E7E7E7;
    padding: 13px 15px;
    margin-bottom: 15px;
}
.select-dealer-modal .search-input-container i {
    position: absolute;
    top: 12px;
    right: 35px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .select-dealer-modal {
        width: auto !important;
    }
    .select-dealer-modal .dealer-item {
        max-height: none;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {  }

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {  }