.personal-data-modal {
    border-radius: 0px !important;
}

.personal-data-modal .column:first-child button {
    font-size: 16px;
    color: #868686;
    border: 1px solid #B2B2B2;
    padding: 10px 15px;
    background-color: white;
    cursor: pointer;
}
.personal-data-modal .column:last-child button {
    font-size: 16px;
    color: white;
    border: none;
    padding: 10px 15px;
    background-color: #c7001f;
    cursor: pointer;
}
.personal-data-modal .column:last-child button img {
    vertical-align: middle;
}