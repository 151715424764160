.history-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.history-page .header {
    margin-right: 30px;
}
.history-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.history-page .header .column {
    position: relative;
    padding-top: 5px;
}

.history-page .header .column:first-child {
    cursor: pointer;
}

.history-page .header .column:first-child img {
    margin-right: 10px;
    vertical-align: baseline;
}


.history-page .year-selector-container {
    position: absolute;
    right: 0px;
    top:0px
}

.history-page .car-selector {
    padding-top: 30px;
}
.history-page .car-selector .car-item {
    display: inline-block;
    width: 286px;
    height: 53px;
    font-size: 16px;
    text-align: center;
    background-color: white;
    color: #868686;
    padding-top: 17px;
    margin-right: 20px;
    cursor: pointer;
}
.history-page .car-selector .car-item.is-long-name {
    padding-top: 7px;
}
.history-page .car-selector .car-item.selected {
    background-color: #C8C8C8;
    color: white;
    font-weight: bold;
    cursor: default;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.history-page .history-items {
    margin-top: 30px;
}
.history-page .history-items .history-item {
    display: inline-block;
    position: relative;
    background-color: white;
    width: 530px;
    height: 248px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-top: 30px;
    padding-left: 20px;
    border: 2px solid white;
    cursor: pointer;
    transition: border-color 300ms;
}

.history-page .history-items .history-item:hover {
    border: 2px solid #C7001F;
}

.history-page .history-items .history-item.is-selected:hover {
    border: 2px solid white;
    cursor: default;
}

.history-page .history-items .history-item .cost {
    position: absolute;
    top: 15px;
    right: 22px;
}
.history-page .history-items .history-item .cost img {
    vertical-align: bottom;
    margin-right: 5px;
}
.history-page .history-items .history-item .time {
    position: absolute;
    top: 15px;
    right: 179px;
}
.history-page .history-items .history-item .time img {
    vertical-align: bottom;
    margin-right: 5px;
}

.history-page .history-items .history-item .dealer-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.history-page .history-items .history-item .order-properties {}
.history-page .history-items .history-item .order-properties .row {
    padding: 3px 0;
}
.history-page .history-items .history-item .order-properties .row .column {}
.history-page .history-items .history-item .order-properties .row .column:first-child {
    color: #B2B2B2;
}
.history-page .history-items .history-item .order-properties .row .column:last-child {
    padding-left: 0px;
}
.history-page .history-items .history-item .quality-assessment {
    position: absolute;
    bottom: 15px;
    left: 20px;
}
.history-page .history-items .history-item .quality-assessment.complete {
    color: #23B062;
}
.history-page .history-items .history-item .quality-assessment.not-complete {
    color: #C7001F;
}
.history-page .history-items .history-item .quality-assessment img {
    vertical-align: bottom;
}
.history-page .history-items .history-item .more-link {
    position: absolute;
    bottom: 15px;
    right: 20px;
    color: #B2B2B2;

    transition: color 300ms;
}

.history-page .history-items .history-item .more-link img {
    vertical-align: bottom;
    transition: display 300ms;
}

.history-page .history-items .history-item .more-link img.on-hover {
    display: none;
}

.history-page .history-items .history-item:hover .more-link img.regular {
    display: none;
}

.history-page .history-items .history-item:hover .more-link img.on-hover {
    display: inline-block;
}

.history-page .history-items .history-item:hover .more-link {
    color: #C7001F;

}

.history-page .history-items .history-item:hover .more-link img {
    filter: none;

}


.quality-assessment-container {
    position: relative;
    background-color: white;
    width: 530px;
    padding: 30px;
}
.quality-assessment-container .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
}
.quality-assessment-container .scale {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}
.quality-assessment-container .scale .scale-item  {
    display: inline-block;
    width: 47px;
    height: 47px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding-top: 13px;
}
.quality-assessment-container .scale .scale-1 {background-color: #8A2B30}
.quality-assessment-container .scale .scale-2 {background-color: #993132}
.quality-assessment-container .scale .scale-3 {background-color: #AB353A}
.quality-assessment-container .scale .scale-4 {background-color: #BA3D40}
.quality-assessment-container .scale .scale-5 {background-color: #D14346}
.quality-assessment-container .scale .scale-6 {background-color: #E84A4F}
.quality-assessment-container .scale .scale-7 {background-color: #FBA82C}
.quality-assessment-container .scale .scale-8 {background-color: #E39729}
.quality-assessment-container .scale .scale-9 {background-color: #24B062}
.quality-assessment-container .scale .scale-10 {background-color: #198B4B}
.quality-assessment-container .scale .not-satisfied-label {
    position: absolute;
    left: 0px;
    bottom: 0px;
}
.quality-assessment-container .scale .satisfied-label {
    position: absolute;
    top: 0px;
    width: 60%;
    text-align: center;
    margin-left: 40%;
}
.quality-assessment-container .scale .total-satisfied-label {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.quality-assessment-container .question-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
}

.quality-assessment-container .question-answer-description {
    color: #868686;
    font-size: 13px;
    margin-top: 10px;
}

.quality-assessment-container .not-satisfied-variant {
    margin-top: 20px;
}
.quality-assessment-container .not-satisfied-variant .custom-checkbox-container {
    margin-bottom: 15px;
}

.quality-assessment-container .other-text-field-variant {
    width: 100%;
    background-color: #F5F5F5;
    color: black;
    border: 1px solid #ECECEC;
    border-radius: 0px;
    outline: none;
    padding: 15px 15px;
    margin-bottom: 20px;
}

.quality-assessment-container .finish-btn {
    border-radius: 0px;
    color: white;
    background-color: #c7001f;
    padding: 15px;
    width: 100%;
    cursor: pointer;
    border: none;
    font-weight: bold;
    margin-top: 25px;
}

.quality-assessment-container .finish-btn:disabled {
    opacity: 0.5;
}


.history-page .order-details {
    background-color: white;
    width: 530px;
    padding: 30px 20px;
}
.history-page .order-details .reason-title {
    color: #B2B2B2;
    margin-bottom: 10px;
}
.history-page .order-details .reasons {}
.history-page .order-details .reasons .reason-item {
    margin-bottom: 5px;
}
.history-page .order-details .items-table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
}
.history-page .order-details .items-table thead {}
.history-page .order-details .items-table thead tr {
    height: 50px;
}
.history-page .order-details .items-table thead tr th {
    color: #B2B2B2;
    font-weight: normal;
    text-align: right;
}

.history-page .order-details .items-table thead tr th:nth-child(2) {
    color: #B2B2B2;
    font-weight: normal;
    text-align: left;
}
.history-page .order-details .items-table thead tr th:first-child {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
}
.history-page .order-details .items-table tbody {}
.history-page .order-details .items-table tbody tr {
    height: 35px;
}
.history-page .order-details .items-table tbody tr td {
    text-align: right;
    vertical-align: top;
}

.history-page .order-details .items-table tbody tr td:first-child {
    vertical-align: top;
    text-align: left;
}

.history-page .order-details .items-table tbody tr td:nth-child(2) {
    text-align: left;
    width: 288px;
}
.history-page .order-details .items-table tfoot {}
.history-page .order-details .items-table tfoot tr {}
.history-page .order-details .items-table tfoot tr th {
    border-top: 1px solid #C8C8C8;
    text-align: left;
    padding-top: 10px;
}
.history-page .order-details .items-table tfoot tr td {
    border-top: 1px solid #C8C8C8;
    text-align: right;
    padding-top: 10px;
    font-weight: bold;
}

.history-page .quality-assessment-container, .history-page .history-items-and-order-details {
    display: inline-block;
    vertical-align: top;
}

.history-page .quality-assessment-container {
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 50px;
}

.history-page .not-found-message {
    text-align: center;
    padding-top: 100px;
    font-weight: bold;
    font-size: larger;
    color: #B2B2B2;
}

/* Only exeed */
.rate-master-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 200px;
    cursor: pointer;
    border-radius: 0px;
    color: #181B1D;
    background: #d29f85;
    padding: 15px;
    border: none;
    font-weight: bold;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .history-page {
        padding-left: 10px;
    }

    .history-page .header {
        margin-right: 15px;
        margin-left: 50px;
    }

    .history-page .header h1 {
        font-size: 21px;
    }

    .history-page .year-selector-container {
        top: 70px;
    }

    .history-page .car-selector {
        padding-top: 77px;
    }

    .history-page .car-selector .car-item {
        width: 178px;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .history-page .history-items .history-item {
        width: calc(100% - 15px);
        height: 250px;
    }

    .history-page .history-items .history-item .more-link {
        right: 0px;
    }

    .quality-assessment-container {
        width: auto;
        margin-right: 0px !important;
        padding: 27px !important;
    }

    .quality-assessment-container .scale .scale-item {
        width: 31px;
        height: 31px;
        padding-top: 6px;
    }
    .question-scale-input .scale-item {
        width: 31px !important;
        height: 31px !important;
        padding-top: 6px !important;
    }
    .history-items-and-order-details {
        display: block !important;
    }

    .history-page .order-details {
        width: auto;
        margin-right: 15px;
        margin-bottom: 25px;
    }

    .history-page .car-selector .car-item {
        padding-top: 7px;
    }

    .history-page .header .column:first-child {
        padding-left: 0px;
    }

    .history-page .header .column:first-child img {
        vertical-align: sub;
    }

}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .history-page {
        padding-left: 140px;
    }

    .history-page .history-items .history-item {
        width: auto;
    }
    .history-page .quality-assessment-container {
        width: calc(100% - 15px);
        margin-right: 15px;
    }

    .history-page .order-details {
        width: calc(100% - 15px);
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}
