
.restore-page {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
}

.right-panel {
    position: absolute;
    width: 50%;
    bottom: 0;
    top: 0;
    right: 0;
}

.blur {
    backdrop-filter: blur(8px);
    background: linear-gradient(206.81deg, rgb(255 255 255 / 34%) 1.48%, rgb(255 255 255 / 38%) 48.07%, rgba(255, 255, 255, 0) 100.48%);
}

.white-paper {
    background-color: white;
    opacity: 0.5;
}

.content-panel {
    text-align: center;
    padding-top: 200px;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);
    padding-left: 150px;
    padding-right: 150px;
}

.content-panel h1, .restore-panel h1 {
    font-size: 36px;
    font-family: 'TacticSans-Bld';
    font-weight: normal;
}

.content-panel p {
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
}

.content-panel input, .restore-panel input {
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 20px;
    border-radius: 0px !important;
    outline: none;
    background-color: #F5F5F5;
    border: 1px solid #DFDFDF !important;
}

.restore-page a {
    cursor: pointer;
    color: #C7001F;
}


.login-btn {
    width: 100%;
    background-color: #C7001F;
    color: white;
    border: none;
    padding: 17px 15px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.content-panel .recovery-password-link-container {
    text-align: left;
    font-size: 14px;
    text-decoration: underline;
}

.content-panel .password-input {
    position: relative;
}

.content-panel .password-input img {
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
}

.restore-panel {
    padding-top: 100px;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);
    padding-left: 90px;
    padding-right: 90px;
}

.restore-panel h1 {
    text-align: center;
}

.restore-panel input {
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 20px !important;
    border-radius: 0px !important;
    outline: none;
    background-color: #F5F5F5;
    border: 1px solid #DFDFDF !important;
}

.restore-panel .go-back-message {
    position: absolute;
    top: 30px;
    right: 30px;
}
.restore-panel .go-back-message a {
    margin-left: 10px;
    border: 1px solid #C7001F;
    background-color: transparent;
    color: #C7001F;
    padding: 10px 35px;
    cursor: pointer;
}

.restore-panel .submit {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    color: white;
    background-color: #C7001F;
    border: none;
    padding: 15px 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
}

.captcha-container {
    background-color: #D9D9D9;
    width: 100%;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 10px;
}
.captcha-container img {}

.restore-panel .email-error-message {
    color: #E32E35 !important;
}

.captcha-container #BDC_CaptchaComponent {
    display: inline-block;
    width: 300px;
}

.restore-page input.error {
    border-color: #c7001f !important;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) {
    .right-panel {
        width: 100%;
        left: 0;
    }

    .content-panel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .restore-panel {
        padding-left: 9px;
        padding-right: 20px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {
    .right-panel {
        width: 65%;
    }
    
    .content-panel {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .right-panel {
        width: 65%;
    }

    .content-panel {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}

