.news-item-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.news-item-page .header {
    margin-right: 30px;
}
.news-item-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.news-item-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
}

.news-item-page .header .column:last-child button.open-all-news-btn {
    border-radius: 0px;
    background-color: #C8C8C8;
    color: white;
    padding: 15px 31px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.news-item-page .header .column:last-child button.edit-btn {
    border-radius: 0px;
    border: 1px solid #c7001f;
    background-color: white;
    color: white;
    padding: 15px 15px;
    margin-right: 20px;
}
.news-item-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.news-item-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}
.news-item-page .news-items {
    margin-top: 20px;
    margin-left: -30px;
    padding-left: 25px;
    padding-right: 25px;
}

.news-item-page .news-item {
    display: inline-block;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    vertical-align: top;
}

.news-item-page .news-item img.main-img {
    width: 100%;
}

.news-item-page .news-item h2 {
    font-size: 18px;
    font-family: 'Roboto';
}

.news-item-page .news-item .news-type-description {
    color: #B2B2B2;
    font-size: 14px;
    margin-bottom: 15px;
}
.news-item-page .news-item .news-type-description img {
    vertical-align: sub;
    margin-right: 10px;
}

.news-item-page .news-item p {
    font-size: 14px;
}

.news-item-page .news-item .link-container {
    margin-bottom: 10px;
}

.news-item-page .news-item .link-container img {
    vertical-align: middle;
    margin-right: 10px;
}

.news-item-page .news-item .link-container a {
    color: #C7001F;
    text-decoration: underline;
    font-size: 14px;
}

.news-item-page .news-item button {
    background-color: white;
    border: 1px solid #838383;
    border-radius: 0px;
    color: #595959;
    font-weight: normal;
    font-family: 'Roboto';
}

.news-item-page .news-item .news-time-container {
    color: #B2B2B2;
    padding-top: 10px;
}
.news-item-page .news-item .news-time-container img {
    width: 24px;
    vertical-align: middle;
}

.news-item-page .empty-message {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    color: #B2B2B2;
}


.image-container, .news-item-content-container {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 15px;
    padding-left: 0;
    padding-right: 40PX;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .news-item-page {
        padding-left: 15px;
        padding-right: 15px;
    }

    .news-item-page .header h1 {
        font-size: 21px;
    }

    .news-item-page .news-items {
        margin-left: 0px;
    }

    .news-item-page .news-item {
        margin-right: 0px;
    }

    .news-item-page .header {
        margin-right: 0px;
        margin-left: 30px;
    }
    .news-item-page .header .column:last-child button img {
        margin-right: 0px;
    }

    .news-item-page .header .column:last-child button.open-all-news-btn {
        padding: 15px;
    }

    .image-container, .news-item-content-container {
        display: block;
        width: 100%;
        padding-right: 0px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .news-item-page {
        padding-left: 170px;
    }

    .image-container, .news-item-content-container {
        display: block;
        width: 100%;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}