svg.garland {
  width: 100%;
}

#garland {
  margin-top: -30px;
  margin-bottom: -50px;
}

#glow path {
  opacity: 0;
  transition: 1s;
}

.garland_active {
  opacity: 1 !important;
  transition: .5s;
}
