.custom-checkbox-container {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.custom-checkbox-container.is-complete {
    cursor: default;
}

.custom-checkbox-container.with-image-or-color {
    padding-left: 50px;
}

.custom-checkbox-container img.checkbox-image {
    position: absolute;
    top: -3px;
    left: 0px;
}

.custom-checkbox-container img.image-for-item {
    position: absolute;
    top: 1px;
    left: 27px;
    width: 18px;
}

.custom-checkbox-container .color-for-item {
    position: absolute;
    top: 5px;
    left: 31px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 1px rgb(34 60 80 / 20%);
}