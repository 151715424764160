.all-news-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.all-news-page .header {
    margin-right: 30px;
}
.all-news-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.all-news-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
    cursor: pointer;
}
.all-news-page .header .column:first-child img{
    margin-right: 7px;
}

.all-news-page .header .column:last-child button.read-all {
    border-radius: 0px;
    background-color: #C8C8C8;
    color: white;
    padding: 15px 31px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.all-news-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.all-news-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}
.all-news-page .news-items {
    margin-top: 20px;
    margin-right: 46px;
    padding: 25px;
    background-color: white;
}

.all-news-page .news-item {
    margin-bottom: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #DFDFDF;
    position: relative;
    height: 40px;
    padding-top: 10px;
    padding-left: 30px;
}

.all-news-page .news-item .title {
    cursor: pointer;
}
.all-news-page .news-item p {
    font-size: 14px;
}

.all-news-page .news-item .link-container {
    position: absolute;
    top: -1px;
    right: 135px;
}

.all-news-page .news-item .link-container img {
    vertical-align: middle;
    margin-right: 10px;
}

.all-news-page .news-item .link-container a {
    color: #C7001F;
    text-decoration: underline;
    font-size: 14px;
}

.all-news-page .news-item button {
    background-color: white;
    border: 1px solid #838383;
    border-radius: 0px;
    color: #595959;
    font-weight: normal;
    font-family: 'Roboto';
}

.all-news-page .news-item .news-time-container {
    color: #B2B2B2;
    padding-top: 10px;
    position: absolute;
    top: -10px;
    right: 0px;
}
.all-news-page .news-item .news-time-container img {
    width: 24px;
    vertical-align: middle;
}

.all-news-page .news-item .mark-element {
    position: absolute;
    left: 5px;
    top: 10px;
    cursor: pointer;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .all-news-page {
        padding-left: 0px;
        padding-right: 0px;
    }

    .all-news-page .header h1 {
        font-size: 21px;
    }
    
    .all-news-page .header {
        margin-right: 0px;
        margin-left: 0px;
    }
    .all-news-page .header .column:last-child button img {
        margin-right: 0px;
    }

    .all-news-page .header .column:last-child button.open-all-news-btn {
        padding: 15px;
    }

    .all-news-page .header .column:last-child button.read-all {
        padding: 15px 5px;
    }

    .all-news-page .news-items {
        margin-right: 0px;
        padding: 25px 5px;
    }


    .all-news-page .news-item {
        height: auto;
        padding-top: 7px;
        padding-bottom: 16px;
        margin-right: 5px;
        padding-right: 100px;
    }

    .all-news-page .news-item .link-container {
        top: -3px;
        right: 73px;
    }

    .all-news-page .header .column:first-child {
        padding-left: 55px;
        padding-right: 0px;
    }
    
    .all-news-page .header .column:first-child img{
        vertical-align: text-bottom;
    }
    
    
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .all-news-page {
        padding-left: 140px;
    }

    .all-news-page .news-items {
        margin-right: 25px;
    }

    .all-news-page .news-item {
        height: auto;
        padding-top: 7px;
        padding-bottom: 16px;
        margin-right: 5px;
        padding-right: 100px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}