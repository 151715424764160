.faq-modal {
    border-radius: 0px!important;

}

.faq-modal .content {
    background-color: #F5F5F5!important;
}

.faq-modal .content>.title {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
}
.faq-modal .content>.question-groups-container {}
.faq-modal .content>.question-groups-container .question-group-container {
    margin-top: 25px;
}
.faq-modal .content>.question-groups-container .question-group-container .group-name {
    font-size: 16px;
    margin-bottom: 20px;
}
