.login-page {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
}

.right-panel {
    position: absolute;
    width: 50%;
    bottom: 0;
    top: 0;
    right: 0;
}

.blur {
    backdrop-filter: blur(8px);
    background: linear-gradient(206.81deg, rgb(255 255 255 / 34%) 1.48%, rgb(255 255 255 / 38%) 48.07%, rgba(255, 255, 255, 0) 100.48%);
}

.white-paper {
    background-color: white;
    opacity: 0.5;
}

.content-panel {
    text-align: center;
    padding-top: 200px;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);
    padding-left: 150px;
    padding-right: 150px;
}

.content-panel p {
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
}

.login-page a {
    cursor: pointer;
    color: #C7001F;
}

.link-to-main-site-container {
    position: absolute;
    bottom: 50px;
    right: 0px;
    width: 25%;
    top: auto;
    text-align: center;
}

.link-to-main-site-container a {
    font-size: 16px;
}

.login-btn {
    width: 100%;
    background-color: #C7001F;
    color: white;
    border: none;
    padding: 17px 15px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.content-panel .recovery-password-link-container {
    text-align: left;
    font-size: 14px;
    text-decoration: underline;
}

.content-panel .password-input {
    position: relative;
}

.content-panel .password-input img {
    position: absolute;
    top: 14px;
    right: 20px;
    cursor: pointer;
}

.login-page input.error {
    border-color: #E32E35 !important;
    margin-bottom: 0px !important;
}

.login-page .error-message {
    text-align: left;
    color: #c7001f;
    padding-left: 15px;
    margin-bottom: 10px !important;
    padding-top: 10px;
}

.content-panel input {
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 20px;
    border-radius: 0px !important;
    outline: none;
    background-color: #F5F5F5;
    border: 1px solid #DFDFDF !important;
}

.open-faq-btn {
    position: absolute;
    bottom: 50px;
    right: 25%;
    width: 25%;
    top: auto;
    text-align: center;
    cursor: pointer;
    color: #0050C7;
    font-size: 16px;
}

.open-faq-btn img {
    vertical-align: middle;
    margin-right: 5px;
}

a.mobile-app-link {
    color: #0050C7;
    font-weight: 700;
    font-size: 13px;
    text-decoration-line: underline;
    position: absolute;
    top: 30px;
    right: 155px;
    display: none;
}

a.mobile-app-link img {
    vertical-align: middle;
    margin-right: 10px;
}

@media (min-height: 320px) and (max-height: 650px) {
    .content-panel {
        padding-top: 80px !important;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .right-panel {
        width: 100%;
        left: 0;
    }

    .content-panel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .open-faq-btn {
        bottom: 60px;
        width: 100%;
        right: 0;
    }

    .link-to-main-site-container {
        bottom: 20px;
        width: 100%;
    }

    a.mobile-app-link{
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
        display: block !important;
    }
}

@media (min-width: 480px) and (max-width: 900px) and (orientation: landscape) {
    .right-panel, .link-to-main-site-container {
        width: 100% !important;
    }

    .content-panel {
        padding-top: 15px !important;
        padding-left: 50px;
        padding-right: 50px;
    }
    .link-to-main-site-container {
        display: none;
    }
}

@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .right-panel, .link-to-main-site-container {
        width: 65% !important;
    }

    .content-panel {
        padding-left: 50px;
        padding-right: 50px;
    }

    a.mobile-app-link{
        right: 55px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

@media (min-width: 1200px) and (orientation: landscape) {

}