.mark-element {
    background-color: #E1121A;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    opacity: 0.2;
    transition: opacity 500ms;
}

.mark-element.visible {
    opacity: 1;
}