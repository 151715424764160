.message-about-empty-login-container {
    padding-left: 320px;
    padding-right: 50px;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .message-about-empty-login-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {
}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .message-about-empty-login-container {
        padding-left: 144px;
        padding-right: 17px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {
}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {
}