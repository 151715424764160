.custom-dropdown-container {
    margin-bottom: 20px;
    position: relative;
}

.custom-dropdown-container .ui.search.dropdown, .custom-dropdown-container .ui.selection.active.dropdown:hover {
    border-radius: 0px;
    border: 1px solid #DFDFDF;
    padding: 15px 20px;
}

.custom-dropdown-container .ui.search.dropdown>input.search {
    background-color: #F5F5F5 !important;
    padding: 13.5px 20px;
    left: 0px
}

.custom-dropdown-container.with-value .ui.search.dropdown>input.search {
    background-color: white !important;
}

.custom-dropdown-container .ui.search.dropdown {
    width: 100%;
    margin: 0px !important;
}

.custom-dropdown-container .ui.selection.active.dropdown .menu {
    border-color: #DFDFDF !important;
    border-radius: 0px;
}

.custom-dropdown-container .ui.selection.dropdown>.dropdown.icon {
    padding: 16px;
    display: none !important;
}


.custom-dropdown-container .ui.selection.dropdown .menu>.item {
    padding: 20px !important;
}

.custom-dropdown-container .dropdown-chevron {
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
}

.custom-dropdown-container .addition-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.custom-dropdown-container.with-icon .ui.search.dropdown, .custom-dropdown-container.with-icon .ui.selection.active.dropdown:hover {
    padding: 15px 35px;
}

.custom-dropdown-container.with-icon .ui.search.dropdown>input.search {
    padding: 13.5px 35px;
}

.custom-dropdown-container .default.text {
    color: #868686 !important;
}

.custom-dropdown-container input {
    height: 100%!important
}
