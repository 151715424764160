.contacts__container {
  display: flex;
  background-color: white;
  padding: 25px;
  margin-bottom: 20px;
}

.contacts__title_container {
  margin-bottom: 15px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.contacts__title {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 18px;
}

.contacts__add_contact {
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

.contacts__subtitle {
  color: #B2B2B2;
}

.btn_disabled {
  opacity: .5;
}

@media screen and (max-width: 768px) {
  .contacts__grid_container > .row > .column:nth-child(2) {
    margin-top: 28px;
  }
}
